import { useState } from 'react'
import './App.css';

const pStyles = {
  fontWeight: 700,
  textTransform: 'uppercase',
  fontSize: 12,
}

function App() {
  const [show, setShow] = useState(true)
  setInterval(() => setShow(!show), 750)
  return (
    <div className="App">
      <header className="App-header">
        {show && <p style={pStyles}>Disregard</p>}
      </header>
    </div>
  );
}

export default App;
